import styles from "./Feature.module.scss";

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

function Feature({ list }) {
  return (
    <div
      className={styles["container"]}
      style={{ opacity: list.length === 0 ? 0 : 1 }}
    >
      {list.map(({ img, imgClassName, label, subLabel, text }) => (
        <>
          {img && (
            <div className={classNames(styles["image"], imgClassName)}>
              <GatsbyImage image={getImage(img)} />
            </div>
          )}
          {label && (
            <div className={styles["text_container"]}>
              <div className={styles["label"]}>{label}</div>
              {subLabel && <div className={styles["subLabel"]}>{subLabel}</div>}
              {text && <div className={styles["text"]}>{text}</div>}
            </div>
          )}
        </>
      ))}
    </div>
  );
}

Feature.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      img: PropTypes.node,
      imgClassName: PropTypes.string,
      label: PropTypes.string,
      subLabel: PropTypes.string,
      text: PropTypes.string,
    })
  ).isRequired,
};

export default Feature;

import styles from "./FeaturesRow.module.scss";

import React from "react";
import PropTypes from "prop-types";

import Feature from "./feature/Feature";

function FeaturesRow({ strings, locale, children }) {
  return (
    <div className={styles["container"]}>
      <div className={styles["content"]}>{children}</div>
    </div>
  );
}

FeaturesRow.Feature = Feature;

FeaturesRow.propTypes = {
  children: PropTypes.node.isRequired,
  locale: PropTypes.string.isRequired,
  /*eslint-disable react/forbid-prop-types*/
  strings: PropTypes.object.isRequired,
  /*eslint-enable react/forbid-prop-types*/
};

export default FeaturesRow;

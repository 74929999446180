import styles from "./Features.module.scss";

import React from "react";
import PropTypes from "prop-types";

import { graphql, useStaticQuery } from "gatsby";

import i18n from "./Features.i18n.json";

import addI18nContextConsumer from "../i18n/addI18nContextConsumer";

import FeaturesRow from "./features-row/FeaturesRow";

function Features({ strings }) {
  const data = useStaticQuery(graphql`
    query {
      illustrationBrand: file(relativePath: { eq: "illustration/brand.png" }) {
        childImageSharp {
          gatsbyImageData(height: 500, formats: [AUTO, WEBP, AVIF])
        }
      }
      illustrationAddress: file(
        relativePath: { eq: "illustration/address.png" }
      ) {
        childImageSharp {
          gatsbyImageData(height: 500, formats: [AUTO, WEBP, AVIF])
        }
      }
      illustrationPlatforms: file(
        relativePath: { eq: "illustration/platforms.png" }
      ) {
        childImageSharp {
          gatsbyImageData(height: 500, formats: [AUTO, WEBP, AVIF])
        }
      }

      illustrationLocation: file(
        relativePath: { eq: "illustration/Address search_v2.png" }
      ) {
        childImageSharp {
          gatsbyImageData(height: 500, formats: [AUTO, WEBP, AVIF])
        }
      }
      illustrationInstall: file(
        relativePath: { eq: "illustration/Install in seconds_v1.png" }
      ) {
        childImageSharp {
          gatsbyImageData(height: 500, formats: [AUTO, WEBP, AVIF])
        }
      }
      illustrationDistance: file(
        relativePath: { eq: "illustration/Distance Calculator_v1.png" }
      ) {
        childImageSharp {
          gatsbyImageData(height: 500, formats: [AUTO, WEBP, AVIF])
        }
      }

      illustrationResponsive: file(
        relativePath: { eq: "illustration/Responsive_v1.png" }
      ) {
        childImageSharp {
          gatsbyImageData(height: 500, formats: [AUTO, WEBP, AVIF])
        }
      }
      illustrationMetrics: file(
        relativePath: { eq: "illustration/Insightful metrics_v1.png" }
      ) {
        childImageSharp {
          gatsbyImageData(height: 500, formats: [AUTO, WEBP, AVIF])
        }
      }
      illustrationSupport: file(
        relativePath: { eq: "illustration/Helpful support_Preview.png" }
      ) {
        childImageSharp {
          gatsbyImageData(height: 500, formats: [AUTO, WEBP, AVIF])
        }
      }

      illustrationCustomCSS: file(
        relativePath: { eq: "illustration/Custom CSS_v1.png" }
      ) {
        childImageSharp {
          gatsbyImageData(height: 500, formats: [AUTO, WEBP, AVIF])
        }
      }
      illustrationClustering: file(
        relativePath: { eq: "illustration/Smart clustering_v1.png" }
      ) {
        childImageSharp {
          gatsbyImageData(height: 500, formats: [AUTO, WEBP, AVIF])
        }
      }
      illustrationFilter: file(
        relativePath: { eq: "illustration/Filter by tag_v1.png" }
      ) {
        childImageSharp {
          gatsbyImageData(height: 500, formats: [AUTO, WEBP, AVIF])
        }
      }
      illustrationSeo: file(relativePath: { eq: "illustration/seo.png" }) {
        childImageSharp {
          gatsbyImageData(height: 500, formats: [AUTO, WEBP, AVIF])
        }
      }

      illustrationLanguage: file(
        relativePath: { eq: "illustration/Multiple languages_Preview.png" }
      ) {
        childImageSharp {
          gatsbyImageData(height: 500, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  `);
  return (
    <div className={styles["container"]}>
      <div className={styles["bg_container"]} />
      <div className={styles["content"]}>
        <h1>{strings["title"]}</h1>
        <h2>{strings["subtitle"]}</h2>
        <FeaturesRow>
          <FeaturesRow.Feature
            list={[{ img: data.illustrationBrand }, strings["brand"]]}
          />
          <FeaturesRow.Feature
            list={[{ img: data.illustrationAddress }, strings["address"]]}
          />
          <FeaturesRow.Feature
            list={[{ img: data.illustrationSeo }, strings["seo"]]}
          />
        </FeaturesRow>
        <FeaturesRow>
          <FeaturesRow.Feature
            list={[{ img: data.illustrationLocation }, strings["location"]]}
          />
          <FeaturesRow.Feature
            list={[{ img: data.illustrationInstall }, strings["install"]]}
          />
          <FeaturesRow.Feature
            list={[{ img: data.illustrationDistance }, strings["distance"]]}
          />
        </FeaturesRow>

        <FeaturesRow>
          <FeaturesRow.Feature
            list={[{ img: data.illustrationResponsive }, strings["responsive"]]}
          />
          <FeaturesRow.Feature
            list={[{ img: data.illustrationMetrics }, strings["metrics"]]}
          />
          <FeaturesRow.Feature
            list={[{ img: data.illustrationSupport }, strings["support"]]}
          />
        </FeaturesRow>

        <FeaturesRow>
          <FeaturesRow.Feature
            list={[{ img: data.illustrationCustomCSS }, strings["customcss"]]}
          />
          <FeaturesRow.Feature
            list={[{ img: data.illustrationClustering }, strings["clustering"]]}
          />
          <FeaturesRow.Feature
            list={[{ img: data.illustrationPlatforms }, strings["platforms"]]}
          />
        </FeaturesRow>
        <div className={styles["betaInfo"]}>* {strings["beta"]}</div>
      </div>
    </div>
  );
}

Features.propTypes = {
  /*eslint-disable react/forbid-prop-types*/
  strings: PropTypes.object.isRequired,
  /*eslint-enable react/forbid-prop-types*/
};

export default addI18nContextConsumer(i18n)(Features);
